import { memo, FC } from 'react';
import cn from 'classnames';

import { Theme } from 'uikit/common/types';
import { Box } from 'uikit/Box';
import { HtmlBox } from 'uikit/HtmlBox';
import { Icon } from 'uikitv2/Icon';
import classes from './NotificationLine.module.scss';
import { NotificationLineProps } from './types';

export const NotificationLine: FC<NotificationLineProps> = memo(({
  onClick, children, onClose, theme = Theme.dark, mode = 'default', dataTestId, classNameWrap, left, classNameChildren,
}) => {
  return (
    <Box
      className={cn(classes.content, classes[mode], classes[theme], classNameWrap)}
      alignItems="center"
      justifyContent="flex-start"
      onClick={onClick}
      data-testid={dataTestId}
    >
      {left}
      <Box className={classes['text-group']} alignItems="flex-start">
        <Icon name="triangle_info" className={classes['icon-info']} />
        {
          typeof children === 'string'
            ? <HtmlBox className={cn(classes.childrenHtml, classes[theme], classNameChildren)} text={children} theme={theme} />
            : <div className={cn(classes.children, classNameChildren)}>{children}</div>
        }
      </Box>
      {!!onClose && <Icon name="close" fill="none" className={classes['icon-close']} onClick={onClose} />}
    </Box>
  );
});
