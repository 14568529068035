import { useCallback, useRef, useEffect } from 'react';
import { BigNumber } from 'bignumber.js';
import { useThrottledCallback } from 'use-debounce';
import { selectedAddressSelector, selectedWalletTypeSelector } from 'lib/features/wallet';
import BlockchainEventConnector from 'connectors/sdk/BlockchainEventConnector';
import { WalletType } from 'types/wallet';
import { useAuthSecretKeeper } from 'hooks/auth/useAuthSecretKeeper';
import { useAppSelector } from 'lib/hooks';
import useSyncProviders from './useSyncProviders';

export interface WalletInfo {
  chainId?: number | null;
  accounts?: string[];
}

export interface Wallet {
  [WalletType.MetaMask]?: WalletInfo;
}

export type SelectedWalletType = WalletType | null;
export interface Balance { matic?: BigNumber, tee?: BigNumber }

const INTERVAL = 100;

export interface UseWalletInitializerProps {
  skip?: boolean;
  connectWallet: (walletType: WalletType) => void;
}

const useWalletInitializer = ({ skip = true, connectWallet }: UseWalletInitializerProps) => {
  useSyncProviders(); // pre sync providers
  const subscription = useRef<() => void>();
  const { updateBalance } = useAuthSecretKeeper();
  const throttleUpdateBalance = useThrottledCallback(updateBalance, INTERVAL);
  const throttleUpdateBalanceSubscription = useRef<typeof throttleUpdateBalance | null>(null);
  const selectedAddress = useAppSelector(selectedAddressSelector);
  const selectedWalletType = useAppSelector(selectedWalletTypeSelector);

  const onTokenTransfer = useCallback((owner: string, spender: string) => {
    if (selectedAddress && [owner, spender].includes(selectedAddress)) {
      throttleUpdateBalanceSubscription.current = throttleUpdateBalance;
      throttleUpdateBalanceSubscription.current?.();
    }
  }, [selectedAddress, throttleUpdateBalance]);

  useEffect(() => {
    if (!skip && selectedWalletType && connectWallet) {
      connectWallet(selectedWalletType);
    }
  }, [skip, selectedWalletType, connectWallet]);

  const initSubscriptions = useCallback(async () => {
    if (selectedAddress) {
      throttleUpdateBalanceSubscription.current = throttleUpdateBalance;
      throttleUpdateBalanceSubscription.current?.();
      subscription.current = await BlockchainEventConnector.getInstance().onTokenTransfer(onTokenTransfer);
    }
  }, [selectedAddress, throttleUpdateBalance, onTokenTransfer]);

  useEffect(() => {
    initSubscriptions();
    return () => {
      subscription.current?.();
      throttleUpdateBalanceSubscription.current?.cancel?.();
    };
  }, [initSubscriptions]);
};

export default useWalletInitializer;