import { SelectedWalletType } from 'lib/features/wallet/types';
import { WalletType } from 'types/wallet';
import { providers, EIP6963ProviderDetail, EIP1193Provider } from './syncProviders';

const getProviderFromWindow = (walletType: WalletType | null): EIP1193Provider | null => {
  return (window.ethereum as any)?.providerMap?.get(walletType) || null;
};

const getProviderByEIP6963 = (
  walletType: WalletType | null,
  providers: Map<string, EIP6963ProviderDetail>,
): EIP1193Provider | null => {
  return [...providers.entries()].find(([, detail]) => detail?.info?.name === walletType)?.[1]?.provider || null;
};

export const getWeb3ProviderByWalletType = (selectedWalletType: SelectedWalletType) => {
  const provider = getProviderByEIP6963(selectedWalletType, providers) || getProviderFromWindow(selectedWalletType);
  return provider || null;
};

export const getWeb3InstanceByWalletType = async (selectedWalletType: SelectedWalletType) => {
  const provider = getWeb3ProviderByWalletType(selectedWalletType);
  if (!provider) return null;
  const Web3 = (await import('web3')).default;
  return new Web3(provider);
};

export const createSignature = async (selectedWalletType: SelectedWalletType, nonce: string, account: string) => {
  return (await getWeb3InstanceByWalletType(selectedWalletType))!.eth.personal.sign(
    nonce,
    account,
    '', // MetaMask will ignore the password argument here
  );
};