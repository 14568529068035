import React, {
  memo,
  FC,
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';

import { Theme } from 'uikitv2/common/types';
import { TooltipLinkProps } from './types';
import { TooltipLinkPopover } from './TooltipLinkPopover';
import { Tooltip } from '../Tooltip';
import classes from './TooltipLink.module.scss';

const HtmlBox = dynamic(() => import('../../HtmlBox/HtmlBox'), { ssr: false });

export const TooltipLink: FC<TooltipLinkProps> = memo(({
  text,
  description,
  title,
  checkOverflow = false,
  isFullWidth = true,
  classNameTooltip,
  theme = Theme.dark,
}) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const refBlock = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (checkOverflow) {
      setIsOverflow((refBlock?.current?.scrollWidth || 0) > (ref?.current?.clientWidth || 0));
    }
  }, [ref, refBlock, checkOverflow, text]);
  const textBlock = useMemo(() => <HtmlBox theme={theme} text={text} ref={refBlock} />, [text, theme]);
  if ((text || title)) {
    return (
      <Tooltip
        tooltip={
          (isOverflow || !checkOverflow)
            ? <TooltipLinkPopover title={title} text={description || text} />
            : null
        }
        placement="top"
        theme={theme}
        classNamePopoverChildren={classes.popoverChildren}
        className={cn(classes.tooltip, { [classes.tooltipFull]: isFullWidth }, classNameTooltip)}
      >
        <div ref={ref} className={cn(classes.text, classes[theme])}>{textBlock}</div>
      </Tooltip>
    );
  }
  return textBlock;
});

export default TooltipLink;