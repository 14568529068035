'use client';

import {
  useCallback, useState, SyntheticEvent, useMemo,
} from 'react';
import cn from 'classnames';

import { NotificationLine } from 'uikitv2/NotificationLine';
import { Box } from 'uikitv2/Box';
import { Pager } from 'uikitv2/Pager';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { isMobileSelector } from 'lib/features/windowSize';
import { Alert, Notifications } from 'lib/features/alerts/types';
import classes from './NotificationsLine.module.scss';
import { useNotificationsLine } from './hooks/useNotificationsLine';

export const NotificationsLine = () => {
  const theme = useAppSelector(themeSelector);
  const isMobile = useAppSelector(isMobileSelector);
  const {
    alerts, onClose: onCloseNotification,
  } = useNotificationsLine();
  const [currentIndex, setCurrentIndex] = useState(0);
  const onChangeCurrentIndex = useCallback((newCurrent: number) => {
    setCurrentIndex(newCurrent);
  }, []);
  const onClose = useCallback((e: SyntheticEvent, id: Notifications) => {
    setCurrentIndex(0);
    onCloseNotification(e, id);
  }, [onCloseNotification]);
  const pager = useMemo(
    () => (isMobile ? null : <Pager total={alerts.length} index={currentIndex} onChange={onChangeCurrentIndex} theme={theme} />),
    [alerts, currentIndex, onChangeCurrentIndex, theme, isMobile],
  );

  const renderNotification = useCallback((alert: Alert) => {
    const { id, text, mode } = alert || {};
    return (
      <NotificationLine
        key={id}
        mode={mode}
        data-testid={`notification-line-${alert.mode}`}
        onClose={(e) => onClose(e, id as Notifications)}
        theme={theme}
        classNameChildren={classes.lineChildrenBase}
        left={pager}
      >
        {text}
      </NotificationLine>
    );
  }, [onClose, theme, pager]);

  return (
    alerts.length
      ? (
        <Box direction="column" className={cn(classes[theme], classes.lines)}>
          {renderNotification(alerts[currentIndex])}
        </Box>
      ) : null
  );
};
