import { DisplayColumnDef } from '@tanstack/react-table';
import { SortingMethods } from 'lib/features/ordersSortingMethods/types';
import { Item } from 'uikit/Dropdown/types';
import { SortDir, BaseOrder, Order } from 'generated/types';
import { Order as ProviderOrder } from 'hooks/orders/types';
import { OrderFullInfo } from 'hooks/useGetOrdersStatus/types';
import { Column } from './types';

export const options: Item[] = [
  {
    value: SortingMethods.awaitingPayment,
    label: 'Awaiting Payment',
  }, {
    value: SortingMethods.createdDate,
    label: 'Created Date',
  }, {
    value: SortingMethods.modifiedDate,
    label: 'Modified Date',
  },
];

export const TABLE_NAME = 'my-orders';

export const updateSubOrders = (prev: BaseOrder[], order: BaseOrder): BaseOrder[] => {
  const founded = prev.find((item:any) => item.id === order.id);
  return founded
    ? prev.map((item) => {
      if (item.id === order.id) {
        return {
          ...item,
          ...order,
        };
      }
      return item;
    }) : [
      ...prev,
      order,
    ];
};

export const sortMethod = {
  [SortingMethods.awaitingPayment]: [
    { sortBy: 'awaitingPayment', sortDir: SortDir.Desc }, { sortBy: 'origins.modifiedDate', sortDir: SortDir.Desc },
  ],
  [SortingMethods.createdDate]: [{ sortBy: 'origins.createdDate', sortDir: SortDir.Desc }],
  [SortingMethods.modifiedDate]: [{ sortBy: 'origins.modifiedDate', sortDir: SortDir.Desc }],
};

export const getMyOrdersColumns = (): Record<string, DisplayColumnDef<Column>> => ({
  createdDate: {
    id: 'createdDate',
    header: 'Created Date',
    size: 80,
  },
  modifiedDate: {
    id: 'modifiedDate',
    header: 'Modified Date',
    size: 80,
  },
  id: {
    id: 'id',
    header: 'Order ID',
    size: 50,
  },
  status: {
    id: 'status',
    header: 'Status',
    size: 80,
  },
  balance: {
    id: 'balance',
    header: 'Balance',
    size: 80,
  },
  engine: {
    id: 'engine',
    header: 'Engine',
  },
  model: {
    id: 'model',
    header: 'Model',
  },
  dataset: {
    id: 'dataset',
    header: 'Dataset',
    size: 200,
  },
});

export const startPage = 20;

export const getOrderFullInfo = (orders?: Order[], providerOrders?: ProviderOrder[], tunnelOrders?: Order[]): OrderFullInfo[] => {
  if (!orders?.length) return [];
  return orders.map((order) => {
    const providerOrder = providerOrders?.find(({ orderId }) => orderId === order?.id);
    const tunnelClientOrder = providerOrder?.linkedOrders?.[0];
    const tunnelOrder = tunnelClientOrder ? tunnelOrders?.find(({ id }) => id === tunnelClientOrder) : undefined;
    return {
      order,
      providerOrder,
      tunnelOrder: {
        order: tunnelOrder,
      },
    };
  });
};