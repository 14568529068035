/* eslint-disable max-len */
import { OfferType } from '@super-protocol/sdk-js';

export const text = {
  [OfferType.Data]: 'The provider is offering the following pricing plans for scaling of the data usage.',
  [OfferType.TeeOffer]: 'This compute provider is offering the following slots and options.',
  [OfferType.Solution]: 'The provider is offering the following pricing plans for scaling of the solution.',
  [OfferType.Storage]: '',
};

export const getTypeOf = (type: OfferType) => {
  return Object.entries(OfferType).reduce((acc, [k, v]) => ({ ...acc, [v]: k }), {})[type];
};