import { useCallback, useMemo } from 'react';
import {
  useLogoutMutation,
  api as authApi,
  useLazyUpdateMeQuery,
  useLazyGetMeQuery,
} from 'lib/features/secretKeeper/api';
import { authByWallet } from 'lib/features/secretKeeper/thunks';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import useWeb3Provider from 'lib/features/wallet/hooks/useWeb3Provider';
import { balanceBigNumberSelector } from 'lib/features/wallet/selectors';
import {
  providerSelector, userSelector, loadingAuthWalletSelector, accessTokenSelector, isProviderSelector, resetState,
} from 'lib/features/secretKeeper';
import useWalletConnector from 'lib/features/wallet/hooks/useWalletConnector';
import { Providers, User } from 'lib/features/secretKeeper/types';
import { useLazyBalanceOfQuery, useLazyTeeBalanceOfQuery } from 'generated/schemas/common';
import {
  isConnectedSelector, loadingWalletSelector, loadingBalanceSelector,
} from 'lib/features/wallet';
import { getWalletNameByWalletType, parseBalanceResult } from 'lib/features/wallet/helpers';
import { BalanceBigNumber } from 'lib/features/wallet/types';
import { logoutWallet, updateBalance as updateBalanceThunk } from 'lib/features/wallet/thunks';
import { WalletType } from 'types/wallet';

const getWalletTypeByProvider = (provider?: Providers): WalletType | null => {
  switch (provider) {
    case Providers.metamask:
      return WalletType.MetaMask;
    default:
      return null;
  }
};

export interface UseAuthSecretKeeper {
  provider?: Providers | null;
  user?: User | null;
  loading: boolean;
  isUserConnected: boolean;
  isAuthChecked: boolean;
  isProvider: boolean;
  loadingBalance: boolean;
  balance?: BalanceBigNumber;
  logout: () => Promise<void>;
  login: (provider: Providers) => Promise<void>;
  getIsNewProvider: (provider?: unknown) => boolean;
  updateCurrentUser: ReturnType<typeof useLazyUpdateMeQuery>[0];
  getCurrentUser: ReturnType<typeof useLazyGetMeQuery>[0];
  checkCanLogin: (provider?: Providers) => Promise<boolean>;
  updateBalance: () => void;
}

export const useAuthSecretKeeper = (): UseAuthSecretKeeper => {
  const { getWeb3Instance } = useWeb3Provider();
  const accessToken = useAppSelector(accessTokenSelector);
  const { connectWallet } = useWalletConnector();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const isProvider = useAppSelector(isProviderSelector);
  const loadingWallet = useAppSelector(loadingWalletSelector);
  const loadingAuthWallet = useAppSelector(loadingAuthWalletSelector);
  const provider = useAppSelector(providerSelector);
  const isConnectedWallet = useAppSelector(isConnectedSelector);
  const updateMeState = useMemo(() => authApi.endpoints.updateMe.select(null), []);
  const updateMeStateResponse = useAppSelector(updateMeState);
  const [logoutMutation, logoutReponse] = useLogoutMutation();
  const [updateCurrentUser] = useLazyUpdateMeQuery();
  const [getCurrentUser] = useLazyGetMeQuery();
  const balance = useAppSelector(balanceBigNumberSelector);
  const loadingBalance = useAppSelector(loadingBalanceSelector);
  const [getBalanceOf] = useLazyBalanceOfQuery();
  const [getTeeBalanceOf] = useLazyTeeBalanceOfQuery();

  const getBalance = useCallback(async (address: string): Promise<{ matic?: string; tee?: string }> => {
    return {
      matic: await parseBalanceResult(getBalanceOf({ address })),
      tee: await parseBalanceResult(getTeeBalanceOf({ address })),
    };
  }, [getBalanceOf, getTeeBalanceOf]);

  const updateBalance = useCallback(() => {
    return dispatch(updateBalanceThunk({ getBalance }));
  }, [dispatch, getBalance]);

  const logout = useCallback(async () => {
    await logoutMutation().catch(() => {});
    dispatch(resetState());
    dispatch(logoutWallet());
  }, [dispatch, logoutMutation]);

  const loginWallet = useCallback(async (walletType: WalletType) => {
    return connectWallet(
      {
        walletType,
        onError: (e: Error) => {
          throw e;
        },
        isSendMetrics: true,
      },
      {
        onUpdate: (address?: string) => dispatch(authByWallet(address)),
      },
    );
  }, [dispatch, connectWallet]);

  const login = useCallback(async (provider: Providers) => {
    if (provider === Providers.metamask) {
      await loginWallet(WalletType.MetaMask);
    } else {
      throw new Error('Bad provider');
    }
  }, [loginWallet]);

  const isUserConnected = useMemo(() => {
    if (!provider) return false;
    if (provider === Providers.metamask) {
      return isConnectedWallet && !!user;
    }
    return false;
  }, [isConnectedWallet, provider, user]);

  const isAuthChecked = !accessToken || updateMeStateResponse.status !== 'uninitialized';

  const loading = logoutReponse?.isLoading
   || loadingWallet
   || loadingAuthWallet
   || updateMeStateResponse?.isLoading
   || false;

  const getIsNewProvider = useCallback((newProvider?: unknown) => {
    if (!newProvider) return false;
    return Object.values(Providers).includes(newProvider as Providers);
  }, []);

  const checkCanLogin = useCallback(async (provider?: Providers) => {
    if (!getIsNewProvider(provider)) {
      throw new Error('Bad provider');
    }
    const walletType = getWalletTypeByProvider(provider);
    if (!walletType) throw new Error('Wallet type is not defined');
    const web3Instance = await getWeb3Instance(walletType);
    if (!web3Instance) {
      throw new Error(`${getWalletNameByWalletType(walletType)} not installed.`);
    }
    return true;
  }, [getWeb3Instance, getIsNewProvider]);

  return {
    user,
    provider,
    isUserConnected,
    loading,
    isAuthChecked,
    isProvider,
    loadingBalance,
    balance,
    login,
    logout,
    getIsNewProvider,
    updateCurrentUser,
    getCurrentUser,
    checkCanLogin,
    updateBalance,
  };
};