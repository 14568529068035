import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'connectors/server';

export const api = createApi({
  reducerPath: 'server',
  baseQuery,
  tagTypes: ['server'],
  endpoints: (builder) => ({
    checkSite: builder.query<void, string>({
      query(url) {
        return {
          url: `/check-site?url=${encodeURIComponent(url)}`,
        };
      },
    }),
  }),
});

export const {
  useCheckSiteQuery,
  useLazyCheckSiteQuery,
} = api;
