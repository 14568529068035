import { UnknownAction, Slice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies';
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import get from 'lodash.get';
import { isSSR } from 'utils';
import { Storage, GetRTKErrorMessageProps, RTKResponse } from './types';

export const HYDRATE_STORE = 'HYDRATE_STORE';

export const isError = (action: UnknownAction): boolean => {
  return action.type.endsWith('rejected');
};

export const isHydrateStore = (action: UnknownAction): boolean => {
  return action.type === HYDRATE_STORE;
};

export function hydrate<T>(builder: ActionReducerMapBuilder<T>, name: string) {
  builder.addMatcher(isHydrateStore, (_, action: UnknownAction) => {
    return action.payload?.[name];
  });
}

export const createNoopStorage = (): Storage => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    setItem(_key: string, value: any) {
      return Promise.resolve(value);
    },
    removeItem() {
      return Promise.resolve();
    },
  };
};

export const createCookieStorage = (cookies?: ReadonlyRequestCookies): Storage => {
  return {
    getItem(key: string) {
      return Promise.resolve(cookies?.get(key)?.value || getCookie(key));
    },
    setItem(key: string, value: any) {
      return Promise.resolve(isSSR() ? null : setCookie(key, value));
    },
    removeItem(key: string) {
      return Promise.resolve(deleteCookie(key));
    },
  };
};

export const createSessionStorage = () => (!isSSR() ? createWebStorage('session') : createNoopStorage());

export const createLocalStorage = () => (!isSSR() ? createWebStorage('local') : createNoopStorage());

export const getBroatcastActions = (slice: Slice, blackList?: string[]): string[] => {
  if (!slice?.name || !slice?.actions) return [];
  const actions = Object.keys(slice.actions).map((action) => `${slice.name}/${action}`);
  if (blackList?.length) {
    return actions.filter((action) => !blackList.includes(action));
  }
  return actions;
};

export const isFetchBaseQueryError = (
  error: unknown,
): error is FetchBaseQueryError => {
  return typeof error === 'object' && error != null && 'status' in error;
};

export const isErrorWithMessage = (
  error: unknown,
): error is { message: string } => {
  return typeof error === 'object' && error != null && 'message' in error && typeof (error as any).message === 'string';
};

export const getRTKErrorMessage = (err: unknown, props?: GetRTKErrorMessageProps): string | null => {
  const { path, skipStatuses } = props || {};
  if (isFetchBaseQueryError(err)) {
    if ('error' in err) {
      return err.error;
    }
    if (skipStatuses?.includes(err.status)) {
      return null;
    }
    if (path) {
      return get(err?.data, path);
    }
    return JSON.stringify(err?.data || {});
  }
  if (isErrorWithMessage(err)) {
    return err.message;
  }
  return null;
};

export const validateRTKResponse = <T>(response: RTKResponse<T>, props?: GetRTKErrorMessageProps): RTKResponse<T> => {
  const error = (response as { error: unknown })?.error;
  if (!error) {
    return response;
  }
  const errorMessage = getRTKErrorMessage(error, props);
  if (errorMessage === null) return response;
  throw new Error(errorMessage || 'Unknown error');
};