import type {
  EncryptionWithMacIV, Hash, StorageType, OfferAttributes,
} from '@super-protocol/dto-js';
import type {
  ValueOfferSubtype,
} from '@super-protocol/sdk-js';

export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  address?: string;
  nonce?: string;
}

export enum Providers {
  metamask = 'metamask',
}

export interface AccessToken { address?: string; provider?: Providers; id?: string }

export interface SecretKeeperState {
  user?: User | null;
  accessToken?: string | null;
  loadingAuthWallet: boolean;
}

export interface TokenResponse { user: User, accessToken: string }

export interface PostFileProps {
  path: string;
  storageId: string;
  name: string;
  description: string;
  type: ValueOfferSubtype;
  hash: Hash;
  encryption: EncryptionWithMacIV;
  size: string;
  pipelineTypes: OfferAttributes.PipelineType[];
  engines: string[];
}

export interface PatchFileBodyProps {
  name: string;
  description: string;
  type: ValueOfferSubtype;
  pipelineTypes: OfferAttributes.PipelineType[];
  engines: string[];
}
export interface PostFileResult {
  id: string;
}

export interface PatchFileProps {
  body: PatchFileBodyProps;
  id: string;
}

export interface StorjStorageCredentials {
  readAccessToken: string;
  writeAccessToken: string;
}

export interface S3StorageCredentials {
  region?: string;
  writeAccessKeyId: string;
  writeSecretAccessKey: string;
  readAccessKeyId: string;
  readSecretAccessKey: string;
}

export interface File {
  id: string;
  storageId: string;
  userId: string;
  path: string;
  hash: Hash;
  encryption: EncryptionWithMacIV;
  name: string;
  description: string;
  type: ValueOfferSubtype;
  size: number;
  createdAt: string;
  updatedAt: string;
  pipelineTypes: OfferAttributes.PipelineType[];
  engines: string[];
}

export interface Storage {
  id: string;
  isCentralized: boolean;
  userId: string;
  storageType: StorageType;
  bucket: string;
  prefix: string;
  storjCredentials?: StorjStorageCredentials;
  s3Credentials?: S3StorageCredentials;
  createdAt: string;
  updatedAt: string;
}

export interface UserSettings {
  activeStorageId?: string;
  decentralizedStorageId?: string;
}

export type PostStorage = Omit<Storage, 'id' | 'updatedAt' | 'createdAt' | 'isCentralized' | 'userId'>;

export interface GetFilesFilter {
  type?: ValueOfferSubtype,
  pipelineType?: OfferAttributes.PipelineType;
}

export interface GetFilesProps {
  filter?: GetFilesFilter;
}

export interface OrderMeta {
  domain?: string;
  wasAvailable?: boolean;
  model?: { id: string; name: string; },
}

export interface CreateOrderResponse {
  linkedOrders: string[];
  meta: OrderMeta;
  id: string;
  privateKey: string;
  userId: string;
}

export interface Order {
  linkedOrders: string[];
  meta: OrderMeta;
  id: string;
  privateKey: string;
  orderId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

export interface CreateOrderRequest extends Partial<Order> {
  id: Order['id'];
  privateKey: Order['privateKey'];
}

export interface PatchOrderResponse extends Order {}

export interface PatchOrderRequest {
  body: Partial<Omit<Order, 'id' | 'createdAt' | 'updatedAt' | 'privateKey'>>;
  id: string;
}

export interface GetOrderResponse extends Order {}
export interface GetOrderListResponse extends Order {}

export interface GetOrdersRequest {
  filter: { id: string; };
}

export interface GetOrdersListRequest {}