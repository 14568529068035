import { createAsyncThunk } from '@reduxjs/toolkit';
import { isProviderSelector as isProviderOAuth2Selector, userSelector as userOAuth2Selector } from 'lib/features/authOAuth2';
import {
  userSelector as userSecretKeeperSelector,
  isProviderSelector as isProviderSecretKeeperSelector,
} from 'lib/features/secretKeeper';
import { Analytics } from 'utils/analytics';
import getConfig from 'config';
// // eslint-disable-next-line import/no-cycle
// import { RootState } from 'lib/store'; // todo
import { EventProperty } from './types';

export const trackEvent = createAsyncThunk<
void,
  { eventType: string, property?: string | EventProperty },
  { rejectValue: string | Error, state: any } // todo
>(
  'events/trackEvent',
  async ({ eventType, property }, { getState }) => {
    const state = getState();
    const config = getConfig();

    let user;
    if (isProviderOAuth2Selector(state)) {
      user = userOAuth2Selector(state);
    } else if (isProviderSecretKeeperSelector(state)) {
      user = userSecretKeeperSelector(state);
    }
    if (!user) return;

    const { id: userId } = user || {};
    const { deviceId } = state.system || {};

    const analytics = await Analytics
      .getInstance()
      .initialize({
        userId,
        // prepare stacktrace in production mode
        apiUrl: process.env.NODE_ENV === 'production'
          ? '/api/analytics'
          : config.NEXT_PUBLIC_ANALYTICS_API,
        apiKey: config.NEXT_PUBLIC_ANALYTICS_KEY,
        deviceId,
      });
    analytics.trackEvent(eventType, property);
  },
);
