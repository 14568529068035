import { MetaMask } from '@web3-react/metamask';
import { connect as connectToMetaMask } from 'connectors/metamask';
import { WalletType } from 'types/wallet';
import {
  Balance, Wallet, EthereumProvider,
} from './types';

export const getInitialBalance = (): Balance => ({ matic: null, tee: null });

export const getConnectFunc = (walletType: WalletType): ((props: any) => Promise<MetaMask>) | undefined => {
  switch (walletType) {
    case WalletType.MetaMask:
      return connectToMetaMask;
    default:
      return undefined;
  }
};

export const getInitialWallet = (): Wallet => ({});

export const getProviderByWalletType = (providers: EthereumProvider['providers'], walletType: WalletType) => {
  switch (walletType) {
    case WalletType.CoinbaseWallet:
      return providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
    case WalletType.MetaMask:
      return providers.find(({ isMetaMask }) => isMetaMask);
    default:
      return undefined;
  }
};

// fix conflict between coinbase and metamask
export const updateSelectedProvider = async (walletType: WalletType): Promise<void> => {
  const detectEthereumProvider = (await import('@metamask/detect-provider')).default;
  const ethereumProvider = await detectEthereumProvider<EthereumProvider>();
  if (!ethereumProvider?.providers?.length) return;
  const provider = getProviderByWalletType(ethereumProvider.providers, walletType);
  if (!provider?.providerMap?.size) return;
  const newProvider = provider.providerMap.get(walletType);
  if (!newProvider) return;
  provider.setSelectedProvider(newProvider);
};

const walletNames = {
  [WalletType.MetaMask]: 'MetaMask',
};

export const getWalletNameByWalletType = (walletType: WalletType): string => {
  return walletNames[walletType] || '';
};

export const parseBalanceResult = async (promise: Promise<{data?: { result?: string }}>) => {
  try {
    const { data } = await promise;
    return (typeof data?.result === 'string' ? data?.result || '0' : undefined);
  } catch {
    return undefined;
  }
};